import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { globalHistory } from "@reach/router"

function Footer() {
  const path = globalHistory.location.pathname
  const transitionTime = 0.3
  let currentClass

  if (path.startsWith("/about")) {
    currentClass = "footer right"
  } else if (path.startsWith("/projects")) {
    currentClass = "footer left"
  } else if (path.startsWith("/connect")) {
    currentClass = "footer center"
  } else {
    currentClass = "footer default"
  }
  return (
    <div className={currentClass}>
      <AniLink
        duration={transitionTime}
        fade
        to="/connect"
        className="connect"
        activeClassName="active"
        partiallyActive={true}
      >
        Connect
      </AniLink>
    </div>
  )
}

export default Footer
