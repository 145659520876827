import React from "react"
import Helmet from "react-helmet"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Footer from "../components/footer"

import "../assets/styles/normalize.css"
import "../assets/styles/layout.scss"
import logo from "../assets/images/logo.png"

const Layout = ({ children }) => {
  let transitionTime = 0.3

  return (
    <div>
      <Helmet>
        <title>KaGibbs | Personal Portfolio of Kashif Gibbs</title>
      </Helmet>
      <div className="container">
        <div className="header">
          <AniLink
            duration={transitionTime}
            fade
            to="/projects"
            className="projects"
            activeClassName="active"
            partiallyActive={true}
          >
            Projects
          </AniLink>
          <AniLink
            duration={transitionTime}
            fade
            to="/"
            className="home"
            activeClassName="active"
          >
            <img src={logo} alt=" " />
          </AniLink>
          <AniLink
            duration={transitionTime}
            fade
            to="/about"
            className="about"
            activeClassName="active"
            partiallyActive={true}
          >
            About
          </AniLink>
        </div>

        <main>{children}</main>

        <Footer />
      </div>
    </div>
  )
}

export default Layout
